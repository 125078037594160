<template>
  <section class="menuUserFilters">
    <div class="menuUserFilters__containerRow">
      <span class="menuUserFilters__title">Filtros</span>
      <iconic class="menuUserFilters__icon" name="closeBold" @click.native="close('showMenu')" />
    </div>
    <div class="menuUserFilters__container">
      <div class="menuUserFilters__containerRow menuUserFilters__containerRow--pointer" @click="isOpenCities = isOpenCities === 'up' ? 'down' : 'up'">
        <span class="menuUserFilters__title">{{ selectedCity || "Ciudad" }}</span>
        <i :class="`fa fa-solid fa-chevron-${isOpenCities}`" />
      </div>
      <template v-if="isOpenCities === 'up'">
        <li class="menuUserFilters__checkbox" v-for="city in cities" :key="city.cityName">
          <input
            class="mycheck mycheck--radio"
            type="checkbox"
            :name="city.cityName"
            :id="city.cityName"
            v-model="city.isChecked"
            @input="onChangeCity"
          />
          <label :for="city.cityName">{{ city.cityName }}</label>
        </li>
      </template>
    </div>
    <div class="menuUserFilters__container" v-if="officesOptions.length">
      <div
        class="menuUserFilters__containerRow menuUserFilters__containerRow--pointer"
        @click="isOpenOffices = isOpenOffices === 'up' ? 'down' : 'up'"
      >
        <span class="menuUserFilters__title">Sedes</span>
        <i :class="`fa fa-solid fa-chevron-${isOpenOffices}`" />
      </div>
      <template v-if="isOpenOffices === 'up'">
        <li class="menuUserFilters__checkbox" v-for="office in officesOptions" :key="office.officeName">
          <input class="mycheck " type="checkbox" :name="office.officeName" :id="office.officeName" v-model="office.isChecked" />
          <label :for="office.officeName">{{ office.officeName }}</label>
        </li>
      </template>
    </div>
    <template v-if="roleUser">
      <div class="menuUserFilters__container">
        <div class="menuUserFilters__containerRow menuUserFilters__containerRow--pointer" @click="isOpenRoles = isOpenRoles === 'up' ? 'down' : 'up'">
          <span class="menuUserFilters__title">{{ $global.dictionary.roles_es_auto[roleSelected] || roleSelected || "Roles" }}</span>
          <i :class="`fa fa-solid fa-chevron-${isOpenRoles}`" />
        </div>
        <template v-if="isOpenRoles === 'up'">
          <li class="menuUserFilters__checkbox" v-for="role in rolesFilter" :key="role">
            <input
              class="mycheck mycheck--radio"
              type="radio"
              :name="role"
              :id="role"
              :value="role"
              v-model="roleSelected"
              @change="isOpenRoles = 'down'"
            />
            <label :for="role">{{ $global.dictionary.roles_es_auto[role] || role }}</label>
          </li>
        </template>
      </div>
      <div class="menuUserFilters__container" v-if="roleSelected === 'model'">
        <div
          class="menuUserFilters__containerRow menuUserFilters__containerRow--pointer"
          @click="isOpenPlatform = isOpenPlatform === 'up' ? 'down' : 'up'"
        >
          <span class="menuUserFilters__title">{{ platformSelected || "Plataforma" }}</span>
          <i :class="`fa fa-solid fa-chevron-${isOpenPlatform}`" />
        </div>
        <template v-if="isOpenPlatform === 'up'">
          <li class="menuUserFilters__checkbox" v-for="platform in platforms" :key="platform._id">
            <input
              class="mycheck mycheck--radio"
              type="radio"
              :name="platform.name"
              :id="platform.name"
              :value="platform._id"
              v-model="platformSelected"
              @change="isOpenAreas = 'down'"
            />
            <label :for="platform.name">{{ platform.name }}</label>
          </li>
        </template>
      </div>
      <div class="menuUserFilters__container" v-if="this.$userData.role === 'superadmin'">
        <div class="menuUserFilters__containerRow menuUserFilters__containerRow--pointer" @click="isOpenAreas = isOpenAreas === 'up' ? 'down' : 'up'">
          <span class="menuUserFilters__title">{{ areaName || "Areas" }}</span>
          <i :class="`fa fa-solid fa-chevron-${isOpenAreas}`" />
        </div>
        <template v-if="isOpenAreas === 'up'">
          <li class="menuUserFilters__checkbox" v-for="area in areas" :key="area._id">
            <input
              class="mycheck mycheck--radio"
              type="radio"
              :name="area.name"
              :id="area.name"
              :value="area._id"
              v-model="areaSelected"
              @change="isOpenAreas = 'down'"
            />
            <label :for="area.name">{{ area.name }}</label>
          </li>
        </template>
      </div>
      <div class="menuUserFilters__container">
        <div
          class="menuUserFilters__containerRow menuUserFilters__containerRow--pointer"
          @click="isOpenInactive = isOpenInactive === 'up' ? 'down' : 'up'"
        >
          <span class="menuUserFilters__title">Inactivos</span>
          <i :class="`fa fa-solid fa-chevron-${isOpenInactive}`" />
        </div>
        <template v-if="isOpenInactive === 'up'">
          <li class="menuUserFilters__checkbox" v-for="option in inactiveOptions" :key="option.key">
            <input class="mycheck " type="checkbox" :name="option.key" :id="option.key" v-model="option.isChecked" @change="changeInactiveOption" />
            <label :for="option.key">{{ option.name }}</label>
          </li>
        </template>
      </div>
    </template>

    <li class="menuUserFilters__checkbox">
      <div class="menuUserFilters__contentFlag" @click="redFlag = !redFlag">
        <iconic :class="`menuUserFilters__flag menuUserFilters__flag--${redFlag && 'active'}`" name="flag" />
        <label class="menuUserFilters__title">Red flags</label>
      </div>
    </li>
    <div class="menuUserFilters__containerRow menuUserFilters__containerRow--end">
      <button class="menuUserFilters__buttonSet" @click="setFilters">Aplicar</button>
    </div>
  </section>
</template>

<script>
import setfilter from "@/mixins/setfilter";
export default {
  mixins: [setfilter],
  props: ["close"],
  data() {
    return {
      isOpenCities: "down",
      isOpenOffices: "down",
      isOpenRoles: "down",
      isOpenAreas: "down",
      isOpenInactive: "down",
      isOpenPlatform: "down",
      areas: [],
      cities: [],
      officesOptions: [],
      inactiveOptions: [],
      platforms: [
        { _id: "jasmin", name: "Jasmin" },
        { _id: "streamate", name: "Streamate" },
      ],
      selectedCity: "",
      roleSelected: "",
      areaSelected: "",
      platformSelected: "",
      redFlag: false,
      roles: ["model", "monitor", "coordinator", "superadmin", "employee"],
    };
  },
  computed: {
    offices() {
      return this.$store.getters["offices/getData"].filter((office) => office.cityName === this.selectedCity);
    },
    officesToFilter() {
      const namesOffices = this.officesOptions
        .map((option) => {
          if (!option.isChecked) {
            return null;
          }

          return option.officeName;
        })
        .filter(Boolean);

      return namesOffices.join("-");
    },
    inactivesToFilter() {
      const inactiveOptions = this.inactiveOptions;
      const hasAllOption = inactiveOptions.some((option) => option.key === "all" && option.isChecked);

      if (hasAllOption) {
        return "inactivos";
      }
      const keysInactives = inactiveOptions
        .map((option) => {
          if (!option.isChecked || option.key === "all") {
            return null;
          }

          return option.key;
        })
        .filter(Boolean);

      return keysInactives.join("-");
    },
    rolesFilter() {
      if (this.$userData.role === "coordinator") {
        return ["model", "monitor"];
      }
      return this.roles;
    },
    areaName() {
      return this.areas.find(({ _id }) => _id === this.areaSelected)?.name;
    },
    roleUser() {
      return this.$userData.role === "superadmin" || this.$userData.role === "coordinator";
    },
  },
  watch: {
    $cities() {
      if (!this.cities.length) {
        this.cities = this.createCityOptions();
      }

      const { city } = this.$route.query;
      if (city) this.checkCity(city);
    },
    offices() {
      this.officesOptions = this.createOfficeOptions();
      const { offices } = this.$route.query;
      if (offices) this.checkOffices(offices);
    },
  },
  methods: {
    changeInactiveOption(event) {
      const { name, checked } = event.target;
      if (name === "all") {
        this.inactiveOptions.forEach((option) => (option.isChecked = checked));
        return;
      }
      const allOption = this.inactiveOptions.find((option) => option.key === "all");
      const allNotChecked = this.inactiveOptions.some((option) => !option.isChecked && option.key !== "all");

      allOption.isChecked = !allNotChecked;
    },
    createCityOptions() {
      return this.$cities
        .filter(
          (city) =>
            this.$userData.role === "superadmin" ||
            this.$userData.polices?.["see-models"]?.payload ||
            (this.$userData.role === "coordinator" && this.$userData.city === city)
        )
        .map((city) => ({ cityName: city, isChecked: false }));
    },
    createOfficeOptions() {
      return this.offices.map(({ officeName }) => ({ officeName, isChecked: false }));
    },
    createInactiveOptions() {
      const dicInactives = {
        all: "Todos",
        endContract: "termina contrato",
        waiver: "renuncia",
        deserter: "deserta",
      };
      return ["all", "vacaciones", "incapacidad", "licencia", "endContract", "waiver", "deserter"].map((key) => ({
        name: dicInactives[key] || key,
        key,
        isChecked: false,
      }));
    },
    onChangeCity(evt) {
      this.selectedCity = evt.target.checked ? evt.target.name : "";

      this.cities.forEach((city) => {
        if (city.cityName === evt.target.name) {
          return;
        }
        city.isChecked = false;
      });

      this.isOpenCities = "down";
    },
    checkCity(cityName) {
      this.selectedCity = cityName;
      this.cities.forEach((cityOption) => (cityOption.isChecked = cityOption.cityName === cityName));
    },
    checkOffices(offices) {
      const officesFormated = offices.split("-");
      this.officesOptions.forEach((officeOption) => {
        const includeName = officesFormated.includes(officeOption.officeName);
        officeOption.isChecked = includeName;

        if (includeName) this.isOpenOffices = "up";
      });
    },
    checkInactives(inactives) {
      const inactivesFormated = inactives.split("-");
      this.inactiveOptions.forEach((inactiveOption) => {
        const includeName = inactivesFormated.includes(inactiveOption.key);
        inactiveOption.isChecked = includeName;

        if (includeName) this.isOpenInactive = "up";
      });

      this.changeInactiveOption({ target: { name: inactivesFormated[0].key } });
    },
    setFilters() {
      const { city = "", offices = "", isInactive = "", flag = "", area = "", role = "", platform = "" } = this.$route.query;
      const validateRedFlag = (this.redFlag ? "redFlag" : "").toString();
      const isEqualFilters =
        city === this.selectedCity &&
        offices === this.officesToFilter &&
        isInactive === this.inactivesToFilter &&
        flag === validateRedFlag &&
        role === this.roleSelected &&
        platform === this.platformSelected &&
        area === this.areaSelected;

      if (isEqualFilters) {
        return;
      }

      this.setFilter({ srcElement: { name: "isInactive", value: this.inactivesToFilter } });
      this.setFilter({ srcElement: { name: "city", value: this.selectedCity } });
      this.setFilter({ srcElement: { name: "offices", value: this.officesToFilter } });
      this.setFilter({ srcElement: { name: "flag", value: validateRedFlag } });
      this.setFilter({ srcElement: { name: "role", value: this.roleSelected } });
      this.setFilter({ srcElement: { name: "area", value: this.areaSelected } });
      this.setFilter({ srcElement: { name: "platform", value: this.platformSelected } });

      this.close("showMenu");
    },
  },
  async mounted() {
    this.areas = this.$store.state.user.companyAreas;
    this.cities = this.createCityOptions();
    this.inactiveOptions = this.createInactiveOptions();

    const { city, offices, isInactive, flag, area, role, platform } = this.$route.query;
    if (city) this.checkCity(city);
    if (offices) this.checkOffices(offices);
    if (isInactive) this.checkInactives(isInactive);
    if (flag) this.redFlag = flag === "redFlag";
    if (role) this.roleSelected = role;
    if (area) this.areaSelected = area;
    if (platform) this.platformSelected = platform;
  },
};
</script>

<style lang="scss">
.menuUserFilters {
  @include Flex(column, flex-start);
  gap: 13px;
  width: 230px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid $alto;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  &__container {
    @include Flex(column, flex-start);
    width: 100%;
    gap: $mpadding / 5;
  }
  &__containerRow {
    @include Flex(row, space-between);
    width: 100%;
    gap: $mpadding;
    &--pointer {
      cursor: pointer;
    }
    &--end {
      justify-content: center;
    }
  }
  &__title {
    font-weight: 600;
    user-select: none;
    text-transform: capitalize;
  }
  &__icon {
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  &__buttonSet {
    padding: 0 10px;
    height: 30px;
    font-size: 15px;
    font-weight: 600;
    color: $primary-color;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid $primary-color;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #ffffff;
      background: $primary-color;
    }
  }
  &__checkbox {
    @include Flex(row, flex-start);
    width: 100%;
    gap: $mpadding / 2;
    > label {
      cursor: pointer;
      text-transform: capitalize;
      user-select: none;
    }
  }
  &__contentFlag {
    @include Flex(row, flex-start);
    gap: $mpadding / 2;
    > label {
      cursor: pointer;
      text-transform: capitalize;
      user-select: none;
    }
  }
  &__flag {
    cursor: pointer;
    color: #d9d9d9;
    transition: 0.2s ease-in-out;
    &:hover {
      color: $primary-color;
    }
    &--active {
      color: $primary-color;
    }
    &--active:hover {
      color: #d9d9d9;
    }
  }
  .mycheck {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    &--radio {
      border-radius: 50%;
    }
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
</style>
